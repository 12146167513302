import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Web3 from 'web3';

import formatNumber, { contractAddresses, getTeamString, capitalizeFirstChar } from '../../../utils';
import { STAGE_INFO } from '../../../utils/constants';
import { getPendingReward } from '../../../utils/callHelpers';
import PresenterABI from '../../../abi/presenterAbi';

import style from './News.module.scss';

export default function News(props) {
    const {title, icon} = props;

    const [description, setDescription] = useState('');
    const [resultStage, setResultStage] = useState('');
    const [hasMwar, setHasMwar] = useState(false);

    const account = useSelector(state => state.user.userAccount.accounts)[0];
    const cycleInfo = useSelector(state => state.round.cycleInfo);
    const chainId = useSelector(state => state.user.chainId);

    const stageIndex = cycleInfo ? parseInt(cycleInfo.stageIndex) : 0;
    const warTeam = cycleInfo ? parseInt(cycleInfo.warTeam) : null;
    const victimTeam = cycleInfo ? parseInt(cycleInfo.victimTeam) : null;

    const warTeamString = getTeamString(warTeam);
    const victimTeamString = getTeamString(victimTeam);

    const warTeamUpperCaseFirstChar = capitalizeFirstChar(warTeamString ?? '');
    const victimTeamUpperCaseFirstChar = capitalizeFirstChar(victimTeamString ?? '');

    const smallerOne = resultStage &&
        Web3.utils.fromWei(`${parseInt(resultStage) || 0}`) > 0 && Web3.utils.fromWei(`${parseInt(resultStage) || 0}`) < 1;

    useEffect(async () => {
        switch (stageIndex) {
        case STAGE_INFO.STAKING.INDEX:
            setResultStage('');
            setHasMwar(false);
            setDescription('This time for staking. You can withdraw the reward, stake new.');
            break;
        case STAGE_INFO.PRODUCTION.INDEX:
            setResultStage('');
            setHasMwar(false);
            setDescription('Time for production.');
            break;
        case STAGE_INFO.RANDOM_WAR.INDEX:
            setResultStage('');
            setHasMwar(false);
            setDescription('War team will be determined shortly');
            break;
        case STAGE_INFO.VOTING.INDEX:
            setResultStage('');
            setHasMwar(false);
            setDescription(`This time for the voting victim team. All stakers of the ${warTeamUpperCaseFirstChar} Nation can vote now.`);
            break;
        case STAGE_INFO.WAR_TIME.INDEX:
            setResultStage('');
            setHasMwar(false);
            setDescription(`${warTeamUpperCaseFirstChar} Nation is attacking ${victimTeamUpperCaseFirstChar}`);
            break;
        case STAGE_INFO.RESULT.INDEX:
            if (victimTeamUpperCaseFirstChar && window.web3 && chainId) {
                const addresses = contractAddresses(chainId);
                const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses['PRESENTER']);
                const reward = await getPendingReward(presenterContract, victimTeam, account);

                setResultStage(reward);
                setHasMwar(true);
            } else {
                setResultStage(true);
                setHasMwar(false);
            }
            break;
        }
    }, [stageIndex, warTeam, victimTeam, chainId, account]);

    return (
        <div className={style.newsContainer}>
            <div className={style.newsTitle}>
                <img src={icon} className={style.newsIcon} alt={'map_icon'} />
                <span>{title}</span>
            </div>
            <div className={style.newsContent}>
                {!resultStage ? (
                    <p className={style.newsDescription}>{description}</p>
                ) : (
                    hasMwar ? (
                        smallerOne ? (
                            <p className={style.newsDescription}>
                                {warTeamUpperCaseFirstChar} steal &sim;{formatNumber(Web3.utils.fromWei(resultStage))} mwar from {victimTeamUpperCaseFirstChar}
                            </p>
                        ) : (
                            <p className={style.newsDescription}>
                                {warTeamUpperCaseFirstChar} steal {formatNumber(Web3.utils.fromWei(resultStage))} mwar from {victimTeamUpperCaseFirstChar}
                            </p>
                        )
                    ) : (
                        <p className={style.newsDescription}>No war in this cycle</p>
                    )
                )}
            </div>
        </div>
    )
}