import React, { useEffect, useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as TotalStackedIcon } from './img/totalStaked.svg';
import { ReactComponent as DefenseIcon } from './img/defense.svg';
import { ReactComponent as AttackIcon } from './img/attack.svg';
import { ReactComponent as ProductionIcon } from './img/production.svg';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as UpArrow} from './img/up-arrow.svg';
import {ReactComponent as DownArrow} from './img/down-arrow.svg';

import { MultiPrgressBar } from '../MultiProgressBar';
import style from './NationInfo.module.scss';
import { useSelector } from 'react-redux';
import formatNumber, {
} from '../../utils';
import { TEAMS } from '../../utils/constants';
import { DEFAULT_NATION_INFO } from './constants';
import Web3 from 'web3';

export default function NationInfo() {
    const [isHide, setHide] = useState(false);
    const [nationName, updateNationName] = useState('');
    const [nationImg, updateNationImg] = useState(null);
    const [activeTeamInfo, setActiveTeamInfo] = useState(null);

    const isConnect = useSelector(state => state.user?.connectWallet?.isConnect);
    const activeTeam = useSelector(state => state.team.activeTeam);
    const teamInfo = useSelector(state => state.team.teamInfo);

    useEffect(() => {
        if (teamInfo && isConnect) {
            switch (activeTeam) {
            case TEAMS.DOGE.CODE:
                updateNationName(TEAMS.DOGE.NAME_NATION);
                updateNationImg(TEAMS.DOGE.IMG);
                setActiveTeamInfo(teamInfo.doge);
                break;
            case TEAMS.PEPE.CODE:
                updateNationName(TEAMS.PEPE.NAME_NATION);
                updateNationImg(TEAMS.PEPE.IMG);
                setActiveTeamInfo(teamInfo.pepe);
                break;
            case TEAMS.WOJAK.CODE:
                updateNationName(TEAMS.WOJAK.NAME_NATION);
                updateNationImg(TEAMS.WOJAK.IMG);
                setActiveTeamInfo(teamInfo.wojak);
                break;
            default:
                updateNationName(TEAMS.DOGE.NAME_NATION);
                updateNationImg(TEAMS.DOGE.IMG);
                setActiveTeamInfo(teamInfo.doge);
                break;
            }
        } else {
            switch (activeTeam) {
            case TEAMS.DOGE.CODE:
                updateNationName(TEAMS.DOGE.NAME_NATION);
                updateNationImg(TEAMS.DOGE.IMG);
                break;
            case TEAMS.PEPE.CODE:
                updateNationName(TEAMS.PEPE.NAME_NATION);
                updateNationImg(TEAMS.PEPE.IMG);
                break;
            case TEAMS.WOJAK.CODE:
                updateNationName(TEAMS.WOJAK.NAME_NATION);
                updateNationImg(TEAMS.WOJAK.IMG);
                break;
            default:
                updateNationName(TEAMS.DOGE.NAME_NATION);
                updateNationImg(TEAMS.DOGE.IMG);
                break;
            }
            setActiveTeamInfo(DEFAULT_NATION_INFO);
        }
    }, [activeTeam, teamInfo, isConnect]);


    function useWindowSize() {
        const [size, setSize] = useState(0);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerHeight);
                if (window.innerHeight <= 562) {
                    setHide(true);
                } else {
                    setHide(false);
                }
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const height = useWindowSize();

    const attack = activeTeamInfo && (activeTeamInfo?.attackAmount / activeTeamInfo?.totalAmount) * 100;
    const defense = activeTeamInfo && (activeTeamInfo?.defenceAmount / activeTeamInfo?.totalAmount) * 100;
    const production = attack === 0 && defense === 0 && (activeTeamInfo?.factoryAmount === 0 || activeTeamInfo?.factoryAmount === '0')
        ? 0
        : 100 - parseFloat(attack).toFixed(2) - parseFloat(defense).toFixed(2);

    return (
        <>
            {activeTeamInfo &&
                <div className={cn(style.nationInfoContainer,
                    {[style.hide]: isHide})}
                >
                    <img src={nationImg} className={style.nationImage} alt={'nation_image'} />

                    <div className={style.nationInfo}>
                        <div className={style.nationName}>
                            {nationName} <InfoCircleOutlined style={{ fontSize: '11rem', color: '#fff', marginLeft: 5 }} />
                        </div>

                        <div className={style.info}>
                            <div className={style.infoItemBox}>
                                <div className={style.infoItem}>
                                    <TotalStackedIcon className={style.infoImage} />
                                    <div>
                                    Total Staked:&#160;
                                        <span className={style.valueSpan}>
                                            {formatNumber(Web3.utils.fromWei(String(activeTeamInfo.totalAmount)))} <span>MWAR</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={style.infoItemBox}>
                                <div className={style.infoItem}>
                                    <DefenseIcon style={{ fill: '#23a62f' }} className={style.infoImage} />
                                    <div>
                                    Defense:&#160;
                                        <span className={style.valueSpan}>
                                            {parseFloat(defense || 0).toFixed(2)}%&#160;
                                            ({formatNumber(Web3.utils.fromWei(String(activeTeamInfo.defenceAmount)))} <span>MWAR</span>)
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={style.infoItemBox}>
                                <div className={style.infoItem}>
                                    <AttackIcon style={{ fill: '#feb540' }} className={style.infoImage} />
                                    <div>
                                    Attack:&#160;
                                        <span className={style.valueSpan}>
                                            {parseFloat(attack || 0).toFixed(2)}% ({formatNumber(Web3.utils.fromWei(String(activeTeamInfo.attackAmount)))} <span>MWAR</span>)
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={style.infoItemBox}>
                                <div className={style.infoItem}>
                                    <ProductionIcon style={{ fill: '#e64646' }} className={style.infoImage} />
                                    <div>
                                    Production:&#160;
                                        <span className={style.valueSpan}>
                                            {parseFloat(production || 0).toFixed(2)}% ({formatNumber(Web3.utils.fromWei(String(activeTeamInfo.factoryAmount)))} <span>MWAR</span>)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <MultiPrgressBar
                            width={'100%'}
                            direction="left"
                            classes={style.infoProgress}
                            items={[{
                                value: attack || 0,
                                title: '',
                                startColor: '#feb540',
                                endColor: '#feb540'
                            }, {
                                value: defense || 0,
                                title: '',
                                startColor: '#23a62f',
                                endColor: '#23a62f'
                            }, {
                                value: production || 0,
                                title: '',
                                startColor: '#e64646',
                                endColor: '#e64646'
                            }]}
                        />
                    </div>
                </div>
            }
            {height <= 562 &&
				<div className={cn({
				    [style.showBtn]: isHide,
				    [style.hideBtn]: !isHide
				})}
				onClick={() => {setHide(!isHide)}}
				>
				    <span role={'button'}>
				        {isHide ? <UpArrow /> : <DownArrow />}
				    </span>
				</div>
            }
        </>

    )
}