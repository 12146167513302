import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3';
import moment from 'moment';
import lottie from 'lottie-web';
import { notification } from 'antd';

import { TEAM_NAME } from './constants';
import { TEAMS } from '../../utils/constants';

import Modal from '../Modal';
import Button from '../Button';
import RadioGroupCustom from '../RadioGroupCustom';

import { storeTransactionLog } from '../../store/modules/User/actions';
import PresenterABI from '../../abi/presenterAbi';
import { getVoteInfo, castVoteForVictimTeamCallback, ERROR_CODE_TIME_OUT } from '../../utils/callHelpers';
import formatNumber, {
    contractAddresses,
    getTeamIndex,
    capitalizeFirstChar
} from '../../utils';

import coinImage from './assets/coin.png';
import style from './VoteModal.module.scss';

const ID_VOTE_LOADING = 'vote-loading';

const getAvatarImage = (itemSelected) => {
    let imgSrc = coinImage;

    switch (itemSelected) {
    case TEAM_NAME.DOGE:
        imgSrc = TEAMS.DOGE.IMG;
        break;

    case TEAM_NAME.PEPE:
        imgSrc = TEAMS.PEPE.IMG;
        break;

    case TEAM_NAME.WOJAK:
        imgSrc = TEAMS.WOJAK.IMG;
        break;

    default:
        break;
    }

    return imgSrc;
}

const NAME_LIST = ['doge', 'pepe', 'wojak'];

export default function VoteModal(props) {
    const dispatch = useDispatch();

    const { handleClose, warTeam, stageIndex, ...restProps } = props;

    const [nationItem, setNationItem] = useState(null);
    const [isVoting, setVoting] = useState(false);
    const [isVoted, setVoted] = useState(false);

    const isConnected = useSelector(state => state.user?.connectWallet?.isConnect);
    const teamInfo = useSelector(state => state.team.teamInfo);
    const chainId = useSelector(state => state.user.chainId);
    const account = useSelector(state => state.user.userAccount.accounts)[0];
    const cycleIndex = useSelector(state => state.round?.cycleIndex);

    const nationList = [{
        displayName: 'Doge Nation',
        description: `${teamInfo ? formatNumber(Web3.utils.fromWei(teamInfo.doge.totalAmount), '', 0) : 0} MWAR Staked`,
        image: TEAMS.DOGE.IMG_SMALL,
        value: TEAM_NAME.DOGE,
        color: '#feb540'
    }, {
        displayName: 'Pepe Nation',
        description: `${teamInfo ? formatNumber(Web3.utils.fromWei(teamInfo.pepe.totalAmount), '', 0) : 0} MWAR Staked`,
        image: TEAMS.PEPE.IMG_SMALL,
        value: TEAM_NAME.PEPE,
        color: '#23a62f'
    }, {
        displayName: 'Wojak Nation',
        description: `${teamInfo ? formatNumber(Web3.utils.fromWei(teamInfo.wojak.totalAmount), '', 0) : 0} MWAR Staked`,
        image: TEAMS.WOJAK.IMG_SMALL,
        value: TEAM_NAME.WOJAK,
        color: '#e64646'
    }];

    // warTeam larger than 2 that is the team war is not chosen
    const activeTeamIndex = warTeam > 2 ? '' : nationList[warTeam].value;

    useEffect(async () => {
        if (!isVoting) return;
        let voteLoadingAnimation = null;

        const voteLoadingData = await import('./LoadingAnimation/loadingAnimation.json');
        voteLoadingAnimation = lottie.loadAnimation({
            container: document.getElementById(ID_VOTE_LOADING),
            animationData: voteLoadingData,
            // renderer: 'svg',
            loop: true,
            autoplay: true,
            name: 'vote-animation'
        });

        voteLoadingAnimation.play();
    }, [isVoting]);

    useEffect(async () => {
        try {
            if (!isConnected) return;
            if (stageIndex !== 3) return; // avoid call contract too many time, just call at voting day.
            if (parseInt(warTeam) > 3) return; // has no war team will not call contract.

            const victimTeams = [0,1,2].filter(e => e !== parseInt(warTeam));

            const presenterContract = new window.web3.eth.Contract(PresenterABI, contractAddresses(chainId)['PRESENTER']);
            const votedA = await getVoteInfo(presenterContract, parseInt(cycleIndex), victimTeams[0], account);
            const votedB = await getVoteInfo(presenterContract, parseInt(cycleIndex), victimTeams[1], account);

            // get voted variable
            const voted = parseInt(votedA) > 0 || parseInt(votedB) > 0;
            setVoted(voted);

            // get itemName is voted
            if (parseInt(votedA) > 0) return setNationItem(NAME_LIST[victimTeams[0]]);
            if (parseInt(votedB) > 0) return setNationItem(NAME_LIST[victimTeams[1]]);
        } catch (error) {
            return error;
        }
    });

    useEffect(() => {
        onClose();
    }, [account])

    const onVote = async() => {
        try {
            const addresses = contractAddresses(chainId);
            const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses['PRESENTER']);
            const teamIndex = getTeamIndex(nationItem);

            // const log = {
            //     date: moment().valueOf(),
            //     type: `Voted ${capitalizeFirstChar(nationItem)} victim team`,
            //     chainId,
            //     account,
            // };

            setVoting(true);

            castVoteForVictimTeamCallback(presenterContract, teamIndex, account, handleVoteResponse);

            // await castVoteForVictimTeam(presenterContract, teamIndex, account).then(res => {
            //     log.isError = false;
            //     log.transactionHash = res?.transactionHash;

            //     dispatch(storeTransactionLog(log));
            //     setVoting(false);
            //     handleClose();
            // }).catch((error) => {
            //     log.isError = true;
            //     log.transactionHash = error?.receipt?.transactionHash;

            //     dispatch(storeTransactionLog(log));
            //     setVoting(false);

            //     notification.error({
            //         message: 'Vote Failed',
            //         description: error?.message
            //     });
            // })

            // handleClose();
        } catch (err) {
            handleVoteResponse(null, err);
            setVoting(false);
        }
    };

    const handleVoteResponse = (receipt, error = null) => {
        const log = {
            date: moment().valueOf(),
            type: `Voted ${capitalizeFirstChar(nationItem)} victim team`,
            chainId,
            account,
        };

        // ERROR
        if (error) {
            // TIMEOUT
            if (error?.code === ERROR_CODE_TIME_OUT) {
                notification.error({
                    message: 'Voted timeout',
                    description: error?.message
                });
                setVoting(false);
                return;
            }

            log.isError = true;
            log.transactionHash = error?.receipt?.transactionHash;

            dispatch(storeTransactionLog(log));
            setVoting(false);

            notification.error({
                message: 'Vote Failed',
                description: error?.message
            });
            return;
        }


        // SUCCESS
        if (receipt && receipt?.transactionHash) {
            log.isError = false;
            log.transactionHash = receipt?.transactionHash;

            dispatch(storeTransactionLog(log));
            setVoting(false);
            handleClose();
        }
    }

    const onClose = () => {
        if (isVoting) return;

        handleClose();
        setNationItem(null);
        setVoting(false);
    }

    return (
        <Modal
            wrapperClass={style.container}
            simple
            width={'390rem'}
            onCancel={onClose}
            {...restProps}
        >
            {isVoting && (
                <>
                    <div style={{ height: 100 }} id={ID_VOTE_LOADING} />
                    <div className={style.placing}>
                        Placing Vote...
                    </div>
                </>
            )}

            {!isVoting && (
                <>
                    <div className={style.header}>
                        <div className={style.avatar}>
                            <img src={getAvatarImage(nationItem)} alt="avatar" className={style.avatarSelected} />
                        </div>
                        <div className={style.heading}>
                            Vote for War
                        </div>
                        <div className={style.description}>
                            Choose which country you want your nation to declare war on.
                        </div>
                    </div>

                    <div className={style.nationList}>
                        <RadioGroupCustom
                            items={nationList}
                            activeTeam={activeTeamIndex}
                            value={nationItem}
                            onChange={(evt) => {
                                setNationItem(evt.target.value);
                            }}
                            isVoted={isVoted}
                            nationItem={nationItem}
                        />
                    </div>

                    <div className={style.buttonGroup}>
                        <Button
                            wrapperClass={style.button}
                            text={'CLOSE'}
                            primary
                            gray
                            onClick={onClose}
                        />

                        <Button
                            wrapperClass={style.button}
                            text={'VOTE'}
                            primary
                            green
                            loading={isVoting}
                            disabled={!nationItem || isVoted}
                            onClick={onVote}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
}
