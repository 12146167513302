import Web3 from 'web3';
import formatNumber, { formatNumberExtend } from '../../utils';
import { TEAMS } from '../../utils/constants';
import attackImage from './assets/attack.png';
import defenseImage from './assets/defense.png';
import productionImage from './assets/production.png';


export const ID_LOADING_STAKE = 'loading-staking'

export const LINK_BUY = process.env.REACT_APP_LINK_BUY

export const TAB_STAKE_POP_UP = [{
    id: 1,
    label: 'NEW STAKE'
}, {
    id: 2,
    label: 'MY STAKE'
}, {
    id: 3,
    label: 'REWARD'
}];

export const getNewStake = (teamInfo) => {
    return (teamInfo ? formatNumber(Web3.utils.fromWei(teamInfo.totalAmount), '', 0) : 0)
}

export const getMyStake = (userInfo) => {
    return (userInfo ? formatNumberExtend(Web3.utils.fromWei(userInfo.totalAmount), '', 0) : 0);
}

export const getReward = (teamInfo) => {
    return (teamInfo ? formatNumber(Web3.utils.fromWei(teamInfo.pendingReward), '', 0) : 0);
}

export const isNewStake = (selectedOption) => {
    return selectedOption.id === 1;
}

export const isMyStake = (selectedOption) => {
    return selectedOption.id === 2;
}

export const isReward = (selectedOption) => {
    return selectedOption.id === 3;
}
export const getNumberByTab = (teamInfo, userInfo, tab) => {

    switch (tab.id) {
    case 1:
        return getNewStake(teamInfo);
    case 2:
        return getMyStake(userInfo);
    case 3:
        return getReward(teamInfo);
    }
}

export const isRewardZero = (nationTeam) => {
    if (!nationTeam) {
        return true;
    }
    return getReward(nationTeam) === 0 || getReward(nationTeam) === '0';
}

export const getNationList = (teamInfo, userInfo, selectedOption) => {
    return [{
        displayName: 'Doge Nation',
        description: `${getNumberByTab(teamInfo?.doge, userInfo?.doge, selectedOption)} MWAR Staked`,
        image: TEAMS.DOGE.IMG_SMALL,
        value: TEAMS.DOGE.CODE
    }, {
        displayName: 'Pepe Nation',
        description: `${getNumberByTab(teamInfo?.pepe, userInfo?.pepe, selectedOption)} MWAR Staked`,
        image: TEAMS.PEPE.IMG_SMALL,
        value: TEAMS.PEPE.CODE
    }, {
        displayName: 'Wojak Nation',
        description: `${getNumberByTab(teamInfo?.wojak, userInfo?.wojak, selectedOption)} MWAR Staked`,
        image: TEAMS.WOJAK.IMG_SMALL,
        value: TEAMS.WOJAK.CODE
    }];
}

export const getNationListReward = (teamInfo, userInfo, selectedOption) => {
    return [{
        displayName: 'Doge Nation',
        description: `${getNumberByTab(teamInfo?.doge, userInfo?.doge, selectedOption)} MWAR`,
        image: TEAMS.DOGE.IMG_SMALL,
        value: TEAMS.DOGE.CODE,
        isPrefix :  isLessOneAndGreaterZero(teamInfo?.doge)
    }, {
        displayName: 'Pepe Nation',
        description: `${getNumberByTab(teamInfo?.pepe, userInfo?.pepe, selectedOption)} MWAR`,
        image: TEAMS.PEPE.IMG_SMALL,
        value: TEAMS.PEPE.CODE,
        isPrefix :  isLessOneAndGreaterZero(teamInfo?.pepe)
    }, {
        displayName: 'Wojak Nation',
        description: `${getNumberByTab(teamInfo?.wojak, userInfo?.wojak, selectedOption)} MWAR`,
        image: TEAMS.WOJAK.IMG_SMALL,
        value: TEAMS.WOJAK.CODE,
        isPrefix :  isLessOneAndGreaterZero(teamInfo?.wojak)
    }];
}

export const getPoolList = (nationTeam) => {
    return [{
        displayName: 'Attack',
        description: `${nationTeam ? formatNumber(Web3.utils.fromWei(String(nationTeam.attackAmount)), '', 0) : 0} MWAR Staked`,
        image: attackImage,
        value: 'attack'
    }, {
        displayName: 'Defense',
        description: `${nationTeam ? formatNumber(Web3.utils.fromWei(String(nationTeam.defenceAmount)), '', 0) : 0} MWAR Staked`,
        image: defenseImage,
        value: 'defense'
    }, {
        displayName: 'Production',
        description: `${nationTeam ? formatNumber(Web3.utils.fromWei(String(nationTeam.factoryAmount)), '', 0) : 0} MWAR Staked`,
        image: productionImage,
        value: 'production'
    }]
}

const isLessOneAndGreaterZero = (data) => {
    if (data?.pendingReward === undefined) {
        return false;
    }
    if (data?.pendingReward && data?.pendingReward > 0 && Web3.utils.fromWei(data.pendingReward) < 1) {
        return true;
    }
    return false;
}